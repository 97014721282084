<style lang="less" scoped>
    .pay-type-box{
        padding: 0.5rem;
    }
    .pay-type-tit{
        padding:0 0.5rem 0.5rem;
        border-bottom: 0.05rem solid #f7f7f7;
    }
    .pay-type-item{
        position: relative;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .pay-type-item-img{
        padding-top:0.2rem;
    }
    .pay-type-item-img .img-box{
        width: 1rem;height: 1rem;
    }
    .pay-type-item-img .img-box1{
        width: 1.8rem;height: 1.8rem;
    }
    .pay-type-btn-box{
        padding: 0.5rem;text-align: center
    }
    .pay-type-btn{
        background:linear-gradient(90deg,rgba(255,200,13,1) 0%,rgba(255,130,0,1) 100%);
        border-radius:2rem;height: 2.25rem;line-height: 2.25rem;text-align: center;
        color: #fff;
        font-size: 0.8rem;
    }
    .pay-type-money{
        position: absolute;
        right: 2.5rem;
        font-size: 0.8rem;
    }
    .isNull{
        text-align:center;
        font-size:0.9rem;
        color: red
    }
    .surplus-price{
        width: 100%;
        text-align: center;
        font-size: 1.8rem;
        color:rgba(63, 69, 98, 1);
        font-weight: bold;
        margin: 1.5rem 0 2.5rem;
    }
    .surplus-payType{
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-around
    }
    .surplus-payType-item{
        display: flex;
        align-items: center;
        justify-content: center
    }
    .surplus-price-btn-box{
        margin: 1rem 0 .5rem;
        display: flex;
        align-items: center;
        justify-content: space-between
    }
    .surplus-price-btn{
        width: 48%;
        background:linear-gradient(90deg,rgba(255,200,13,1) 0%,rgba(255,130,0,1) 100%);
        border-radius:2rem;height: 2.25rem;line-height: 2.25rem;text-align: center;
        color: #fff;
        font-size: 0.8rem;
    }
    .surplus-price-btn1{
        background: rgba(249, 248, 247, 1);
        color: rgba(3, 3, 3, 1);
        font-size: 0.8rem;
    }
</style>
<template>
    <van-action-sheet v-model="show" :title="!isSurplus? '选择支付方式':'余额充值差额支付'">
        <div v-if="!isSurplus" class="pay-type-box">
            <div class="pay-type-list" v-if="paymentType.length > 0">
                <van-radio-group v-model="selectPay">
                    <div class="pay-type-item"
                        @click="handleClickToggleType(item.type)"
                        v-for="item in paymentType" 
                        :key="item.type">
                        <div class="pay-type-item-img">
                            <van-image fit="cover"
                                class="img-box"
                                :src="item.icon">
                                <template v-slot:loading>
                                    <van-loading type="spinner" size="20" />
                                </template>
                            </van-image>
                        </div>
                        <div style="width:80%;font-size:0.88rem"> {{item.name}}</div>
                        <!-- <div class="pay-type-money" v-if="item.type == 5">余额：￥{{Number(balance).toFixed(2)}}</div> -->
                        <div class="pay-type-money" style="color:rgba(151, 151, 151, 1)" v-if="item.type == 5 && Number(totalMoney) > Number(balance)">余额：￥{{Number(balance).toFixed(2)}}</div>
                        <div class="pay-type-money" v-if="item.type == 5 && Number(totalMoney) <= Number(balance)">余额：￥{{Number(balance).toFixed(2)}}</div>
                        <div>
                            <van-radio :name="item.type" checked-color="#ED6A0C">
                            </van-radio>
                            <!-- <van-radio :name="item.type" :disabled="item.type == 5 && (Number(totalMoney) > Number(balance))?true:false" checked-color="#ED6A0C">
                            </van-radio> -->
                        </div>
                    </div>
                </van-radio-group>
            </div>
            <div class="isNull" v-if="paymentType.length == 0">
                商家未设置付款方式，请联系商家
            </div>
            <div v-if="selectPay == 5 && Number(totalMoney) > Number(balance)"  class="pay-type-btn-box" @click="isSurplus = true">
                <div class="pay-type-btn">余额不足，充值差额(￥{{(Number(totalMoney) - Number(balance)).toFixed(2)}})支付</div>
            </div>
            <div v-else-if="selectPay == 5 && Number(totalMoney) <= Number(balance)" class="pay-type-btn-box" @click="handleChangePayType">
                <div class="pay-type-btn">余额充足，使用余额支付</div>
            </div>
            <div v-else  class="pay-type-btn-box" @click="handleChangePayType">
                <div class="pay-type-btn">使用{{ selectPay | PayTypeFilter }}</div>
            </div>
        </div>
        <div v-else class="pay-type-box">
            <div class="surplus-price">￥{{(Number(totalMoney) - Number(balance)).toFixed(2)}}</div>
            <van-radio-group v-model="selectSurplusPay" class="surplus-payType">
                <div class="surplus-payType-item" v-if="isWeChat" @click="handleChangeSurplusPayType(2)">
                    <div class="pay-type-item-img">
                        <van-image fit="cover" class="img-box1" :src="wechat">
                        </van-image>
                    </div>
                    <div style="margin:0 .5rem;font-size:0.88rem">微信支付</div>
                    <van-radio :name="2" checked-color="#ED6A0C"></van-radio>
                </div>
                <div class="surplus-payType-item" v-if="isAipay" @click="handleChangeSurplusPayType(4)">
                    <div class="pay-type-item-img">
                        <van-image fit="cover" class="img-box1" :src="aipay">
                        </van-image>
                    </div>
                    <div style="margin:0 .5rem;font-size:0.88rem">支付宝支付</div>
                    <van-radio :name="4" checked-color="#ED6A0C"></van-radio>
                </div>
            </van-radio-group>
            <div class="surplus-price-btn-box">
                <div class="surplus-price-btn surplus-price-btn1" @click="isSurplus = false">返回 选择支付方式</div>
                <div class="surplus-price-btn" @click="handleChangePayType">确认支付</div>
            </div>
        </div>
    </van-action-sheet>
</template>
<script>
// 支付方式
const PayTypeMap = {
    '1': {
        text: '发货付款'
    },
    '2': {
        text: '微信支付'
    },
    '3': {
        text: '微信转账'
    },
    '4': {
        text: '支付宝支付'
    },
    '5': {
        text: '余额支付'
    },
    '6': {
        text: '现场支付'
    }
}
import aipay from "@/assets/image/aipay.png"
import wechat from "@/assets/image/wechat.png"
import hdfk from "@/assets/image/hdfk.png"
import yue from "@/assets/image/yue.png"
import yue2 from "@/assets/image/yue2.png"
import { paytype, GetUserBalance } from "@/apis/api"
export default {
    name:"pay-type",
    props:{
        propShow:Boolean,
        payType:{
            type:String,
            default: ''
        },
        totalMoney: {
            type:Number,
            default:0
        }
    },
    data(){
        return{
            aipay,
            wechat,
            show: false,
            selectPay:0,
            //支付方式
            paymentType:[],
            // 余额
            balance:0,
            isSurplus: false, //是否是差额支付 如果是差额支付就要选择支付方式
            selectSurplusPay:2,
            isWeChat:false,
            isAipay:false
        }
    },
    filters: {
        PayTypeFilter (state) {
            return state ? PayTypeMap[state].text : '-'
        }
    },
    watch:{
        //监听父组件传来的show
        propShow: {
            immediate: true,
            handler (v) {
                this.show = v;
                if(this.show){
                    this.getPaytype();
                    this.getBalance()
                }
                this.isSurplus = false
            }
        },
        //修改父组件的show
        show(v){
            if(v === false){
                this.$emit("handleClickPropShow")
            }
        },
        paymentType: {
            deep:true,
            immediate: true,
            handler (v) {
                if(v.filter(item => item.type == 2).length > 0){
                    this.isWeChat = true
                }else{
                    this.isWeChat = false
                }
                if(v.filter(item => item.type == 4).length > 0){
                    this.isAipay = true
                }else{
                    this.isAipay = false
                }
            }
        }
    },
    methods:{
        getPaytype(){
            paytype().then(res=>{
                if(res.code== 0){
                    let paymentType = res.res.data
                    if(paymentType.length > 0){
                        paymentType.map((item,index)=>{
                            if(this.payType){
                                // if(Number(this.totalMoney) > Number(this.balance)){
                                //     this.selectPay = paymentType[0].type
                                // }else{
                                //     this.selectPay = this.payType
                                // }
                                this.selectPay = this.payType
                            }else{
                                this.selectPay = paymentType[0].type
                            }
                            if(item.type == 1){
                                if(localStorage.getItem('sysOfficeId') === '2d438ec112d442058dc16fc7debe8da6'){
                                    item.name = "发货付款"
                                }else{
                                    item.name = "货到付款"
                                }
                                item.icon = hdfk
                            }
                            if(item.type == 2){
                                item.name = "微信支付"
                                item.icon = wechat
                            }
                            if(item.type == 3){
                                item.name = "微信转账"
                                item.icon = wechat
                            }
                            if(item.type == 4){
                                item.name = "支付宝支付"
                                item.icon = aipay
                            }
                            if(item.type == 5){
                                item.name = "余额支付"
                                item.icon = yue
                                // if(Number(this.totalMoney) > Number(this.balance)){
                                //     item.name = "余额不足"
                                //     item.icon = yue2
                                // }else{
                                //     item.name = "余额支付"
                                //     item.icon = yue
                                // }
                            }
                        })
                    }
                    this.paymentType = paymentType
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        getBalance(){
            let params = {
                customer_id: localStorage.getItem('customerId')
            }
            GetUserBalance(params).then(res=>{
                if(res.code== 0){
                    this.balance = res.res.balance
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        handleClickToggleType(type){
            // if(Number(this.totalMoney) > Number(this.balance) && type == 5){
            //     return false
            // }else{
            //     this.selectPay = type
            // }
            this.selectPay = type
        },
        handleChangeSurplusPayType(type){
            console.log(type)
            this.selectSurplusPay = type
        },
        handleChangePayType(){
            // if(Number(this.totalMoney) > Number(this.balance) && this.selectPay == 5){
            //     this.$toast('余额不足,换一种支付方式吧')
            //     return false
            // }
            let paymentType = [...this.paymentType]
            let difference = (Number(this.totalMoney) - Number(this.balance)).toFixed(2) >=0?(Number(this.totalMoney) - Number(this.balance)).toFixed(2):0
            let surplusPayInfo = {
                difference: difference, // 差额的钱数
                difference_type: this.isSurplus ? 'insufficient_balance' : ''
            }
            let resultPay = this.isSurplus ? this.selectSurplusPay : this.selectPay
            paymentType.map(item=>{
                if(item.type == resultPay){
                    // item 是选中的支付方式
                    // surplusPayInfo 差额信息
                    this.$emit("handleChangePayType",item,surplusPayInfo);
                }
            })
            this.show = !this.show
        }
    }
}
</script>